  $(document).ready(function(){
   // console.log("running supplies-pop-up");
        var pathname = window.location.hash;
  //      console.log(pathname);
        if(pathname.indexOf('supplies') > -1){
  //          console.log(pathname.indexOf('supplies'));
             setTimeout(function() {
              $(".supplies-pop-up")[0].click();
   //           console.log("supplies-pop-up clicked");
             },1000);
            }
  });

    $(document).ready(function(){
   // console.log("running features-pop-up");
        var pathname = window.location.hash;
  //      console.log(pathname);
        if(pathname.indexOf('features') > -1){
  //          console.log(pathname.indexOf('features'));
             setTimeout(function() {
              $(".features-pop-up")[0].click();
   //           console.log("features-pop-up clicked");
             },1000);
            }
  });